import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      // {
      //   label: t('About'),
      //   href: '#',
      // },
      {
        label: t('Contact'),
        href: '/contact',
      },
      {
        label: t('Blog'),
        href: '/blog',
      },
      {
        label: t('Community'),
        href: '/community',
      },
      {
        label: t('Litepaper'),
        href: '#',
      },
    ],
  },
  {
    label: t('Help'),
    items: [
      {
        label: t('Online Store'),
        href: 'https://jointstore.de',
      },
      {
        label: t('Terms'),
        href: '/terms-conditions',
      },
      {
        label: t('Privacy'),
        href: '/privacy',
      },
    ],
  },
  // {
  //   label: t('Developers'),
  //   items: [
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/pancakeswap',
  //     },
  //     {
  //       label: t('Documentation'),
  //       href: 'https://docs.pancakeswap.finance',
  //     },
  //     {
  //       label: t('Bug Bounty'),
  //       href: 'https://docs.pancakeswap.finance/code/bug-bounty',
  //     },
  //     {
  //       label: t('Audits'),
  //       href: 'https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited',
  //     },
  //     {
  //       label: t('Careers'),
  //       href: 'https://docs.pancakeswap.finance/hiring/become-a-chef',
  //     },
  //   ],
  // },
]
